<template>
  <div v-if="isVisible" class="flex items-center font-black animate-pulse" :class="classObject">
    <IconEntypoCircleWithCross
      class="animate-spin ui-loader-icon"
      :class="iconColorClass"
    />
    <slot>
      <span v-if="label" :aria-label="label">{{ label }}</span>
    </slot>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";

// Props
const props = defineProps({
  iconColorClass: {
    type: String,
    default: "text-brand-primary",
  },
  textColorClass: {
    type: String,
    default: "text-slate-800 dark:text-white",
  },
  label: {
    type: String,
    default: "Loading...",
  },
  size: {
    type: String,
    default: "base",
  },
  delay: {
    type: Number,
    default: 300, // Default delay of 300ms
  },
});

// Reactive state
const isVisible = ref(false);

// Computed properties
const classObject = computed(() => ({
  [`ui-loader-${props.size}`]: props.size,
  [props.textColorClass]: props.textColorClass,
}));

// Show loader after delay
onMounted(() => {
  setTimeout(() => {
    isVisible.value = true;
  }, props.delay);
});
</script>

<style lang="postcss">
.ui-loader-base {
  @apply text-base;
  .ui-loader-icon {
    @apply w-5 h-5 mr-1.5;
  }
}

.ui-loader-sm {
  @apply text-sm;
  .ui-loader-icon {
    @apply w-4 h-4 mr-1;
  }
}
</style>
