import { serverTimestamp as firestoreTimestamp } from 'firebase/firestore'
import { serverTimestamp as rtdbTimestamp } from 'firebase/database'
import { httpsCallable } from "firebase/functions";
import { getToken } from 'firebase/app-check';
import { appCheckService, firebaseAuth, functions, functionsExtensions } from "@/modules/firebase.js";
import hexRgb from "hex-rgb";
import { find, has } from "lodash-es";
import { logger } from "@/utils/index.js";

/**
* Returns a placeholder value for auto-populating the current timestamp (time since the Unix epoch, in milliseconds) as determined by the Firebase servers.
* https://firebase.google.com/docs/reference/js/database#servertimestamp
*
* @param {string} dbType - 'firestore' or 'rtdb'
* @returns {object}
*/
const getServerTimestamp = (dbType = 'firestore') => dbType === 'rtdb' ? rtdbTimestamp() : firestoreTimestamp()

/**
 * Formats the date to human readable format
 *
 * @param {*} isoDate
 * @returns
 */
const formatDate = isoDate => (
  !(isoDate === undefined)
    ? new Date(isoDate).toLocaleDateString(
      'en-gb',
      {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      },
    )
    : undefined)

/**
 * Returns a string with the age of the date you provided
 *
 * @param {string} dateISOString String of the date to compare
 * @returns {string} the day / hours ago of the date
 */
const dateAge = (dateISOString) => {
  const ONE_DAY = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
  const ONE_HOUR = 60 * 60 * 1000 // minutes*seconds*milliseconds

  // prepare dates in date object
  const pastDate = new Date(dateISOString)
  const now = new Date()

  // check days ago
  const daysAgo = Math.round(Math.abs((pastDate - now) / ONE_DAY))
  if (daysAgo > 0)
    return (daysAgo === 1) ? `${daysAgo} day ago` : `${daysAgo} days ago`

  // if 0 days ago, check for hours ago
  const hoursAgo = Math.round(Math.abs((pastDate - now) / ONE_HOUR))
  if (hoursAgo > 0)
    return (hoursAgo === 1) ? `${hoursAgo} hour ago` : `${hoursAgo} hours ago`

  // else return moments ago
  return 'moments ago'
}


const setCSSColorVariables = (primaryColor, secondaryColor)=> {
  if (!import.meta.env.SSR && window?.document) {
    // Targets to update
    const appRoot = document?.querySelector(":root");
    const previewer = document.querySelector("#previewer");
    const previewerRoot = previewer?.contentDocument?.querySelector(":root")
    const targets = [appRoot, previewerRoot]

    // Convert hex to rgb
    const primaryRgb = hexRgb(
      primaryColor || "#000000"
    );
    const secondaryRgb = hexRgb(
      secondaryColor || "#000000"
    );

    targets.forEach(el => {
      // Set the value of variable
      el?.style?.setProperty(
        "--color-primary",
        `${primaryRgb.red} ${primaryRgb.green} ${primaryRgb.blue}`
      );
      el?.style?.setProperty(
        "--color-secondary",
        `${secondaryRgb.red} ${secondaryRgb.green} ${secondaryRgb.blue}`
      );
      // Set the video player colour
      el?.style?.setProperty(
        "--plyr-color-main",
        primaryColor
      );
    });
  }
}

const getFirstKeyOfObject = (obj) => {
  for (var key in obj) {
    break
  }
  return key
}

const getPageIdFromPath = (pages = {}, path) => {
  const page = find(pages, (page) => {
    return page?.slug === path
  });

  return page?.id;
};


/**
 *
 * Calls a firebase function
 *
 * @param {*} functionName the name of the function to call
 * @param {*} [params={}] the params to pass to the function
 * @param {boolean} [useFirebaseExtensions=false] whether to use the firebase extensions or not
 * @returns {Promise} the response from the function
 */
const callableFunction = async (functionName, params = {}, timeout = 70000) => {
  logger.log(`Calling ${functionName}...`, { functionName, params, timeout });

  let functionCall;
  try {
    functionCall = httpsCallable(functions, functionName, { timeout });
  } catch (error) {
    // Handle error during httpsCallable creation
    logger.error('Error creating callable function', { error });

    // Check if the error is an Unauthenticated error
    if (error.code === 'unauthenticated' || error.message.includes('Unauthenticated')) {
      logger.warn('Unauthenticated error occurred during httpsCallable. Refreshing App Check token and retrying...', { error });

      // Refresh the App Check token
      try {
        await getToken(appCheckService, true); // Force refresh the token

        // Retry creating the callable function
        functionCall = httpsCallable(functions, functionName, { timeout });
      } catch (retryError) {
        logger.error('Retry after refreshing App Check token failed during httpsCallable', { retryError });
        throw retryError;
      }
    } else {
      // Handle other errors
      throw error;
    }
  }

  // Proceed to call the function if creation was successful
  let response;
  try {
    response = await functionCall(params);
  } catch (callError) {
    // Handle errors during function call
    logger.error('Error calling function', { callError });

    // Similar error handling as before
    if (callError.code === 'unauthenticated' || callError.message.includes('Unauthenticated')) {
      logger.warn('Unauthenticated error occurred during function call. Refreshing App Check token and retrying...', { callError });

      // Refresh the App Check token
      try {
        await getToken(appCheckService, true); // Force refresh the token

        // Retry the function call
        response = await functionCall(params);
      } catch (retryCallError) {
        logger.error('Retry after refreshing App Check token failed during function call', { retryCallError });
        throw retryCallError;
      }
    } else {
      throw callError;
    }
  }

  // Process the response as before
  const success = response?.data?.success;
  const data = response?.data?.data || response?.data;
  const errorData = response?.data?.error || `Function call ${functionName} failed`;

  if (has(response, 'data.success') && success === false) {
    logger.error(errorData, { functionName, params, response });
    throw new Error(errorData);
  }

  logger.log(`Called ${functionName} successfully!`, { data });
  return data;
};


const callableExtensions = async (functionName, params = {}) => {
  const functionCall = httpsCallable(functionsExtensions, functionName);
  const response = await functionCall(params);
  return response?.data?.data || response?.data;
};

export { getFirstKeyOfObject, getPageIdFromPath, getServerTimestamp, formatDate, dateAge, setCSSColorVariables, callableFunction, callableExtensions}
